export type RoleType =
  | "superAdministrator"
  | "administrator"
  | "agent"
  | "agency"
  | "agency_admin"
  | "capo_area"
  | "group_manager"
  | "customer";

export enum RoleEnum {
  SUPERADMINISTRATOR = "superAdministrator",
  ADMINISTRATOR = "administrator",
  JUNIOR_AGENT = "junior_agent",
  AGENT = "agent",
  AGENCY = "agency",
  CAPO_AREA = "capo_area",
  GROUP_MANAGER = "group_manager",
  AGENCY_ADMIN = "agency_admin",
  CUSTOMER = "customer",
}
