import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { RouteType } from "components/Router/Router";
import { Link } from "react-router-dom";

type DrawerItemProps = {
  route: RouteType;
  title: string;
  hasSubMenu?: boolean;
  isSubMenuOpen?: boolean;
  setOpen?: () => void;
};

function DrawerItem(props: DrawerItemProps) {
  const { route, title, hasSubMenu, isSubMenuOpen, setOpen } = props;

  return (
    <Link
      to={route.path}
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
    >
      <ListItem key={route.id} disablePadding>
        <ListItemButton>
          <ListItemText
            primary={title}
            sx={{
              "& span": {
                fontSize: "1.2rem",
                color: "black",
                fontWeight: 700,
                py: "0.2rem",
                position: "relative",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-0.8rem",
                  left: 0,
                  width: hasSubMenu ? "calc(100% + 3rem)" : "100%",
                  height: "1px",
                  backgroundColor: "var(--primary)",
                  display: isSubMenuOpen ? "none" : "block",
                },
              },
            }}
          />
          {hasSubMenu ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setOpen && setOpen();
              }}
              style={{
                borderRadius: 0,
                width: "3rem",
              }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              {isSubMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          ) : null}
        </ListItemButton>
      </ListItem>
    </Link>
  );
}

export default DrawerItem;
